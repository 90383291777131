import './Header.css';

const Header = (props : any) => {
    return (
        <div className="header">
            <span>{props.name}</span>
        </div>
    );
};

export default Header;