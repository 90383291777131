import "./Footer.css";

const Footer = () => {
    return (
        <footer id="footerContainer">
            <div>
                <figure>
                    <img
                        src="https://res.cloudinary.com/du0jhqyku/image/upload/v1700001928/phone-solid_1_-svg_1_awspy9.svg"
                        alt="Logo de localisation"
                    />
                    <span>Ile-De-France</span>
                </figure>
                <figure>
                    <img
                        src="https://res.cloudinary.com/du0jhqyku/image/upload/v1700001848/envelope-regular-svg_etlr6y.svg"
                        alt="Logo de mail"
                    />
                    <span>Martin.serrures@protonmail.com</span>
                </figure>
                <figure>
                    <img
                        src="https://res.cloudinary.com/du0jhqyku/image/upload/v1700001847/phone-solid_1_-svg_dpios5.svg"
                        alt="Logo de téléphone"
                    />
                    <span>06 66 47 16 17</span>
                </figure>
            </div>
        </footer>
    );
};

export default Footer;
