import "./Accueil.css";

const Accueil = () => {
    return (
        <div id="homeContainer">
            <div id="AboutContainer">
                <div id="articleContent">
                    <article>
                        <img
                            src="https://res.cloudinary.com/du0jhqyku/image/upload/v1699822639/lkld8jznxh5m3kjaysz9.svg"
                            alt="le serurier"
                        />
                    </article>
                    <article>
                        <h1>
                            <span>Martin</span> Serrures
                        </h1>
                        <p>
                            Bienvenue chez Martin Serrures ! En tant que
                            serruriers dévoués, nous sommes là pour vous offrir
                            des solutions rapides et fiables pour tous vos
                            besoins en serrurie. Que vous ayez claqué votre
                            porte, oublié vos clés à l'intérieur ou que vous
                            souhaitez renforcer la sécurité de votre domicile,
                            notre équipe est là pour vous aider.
                            <br></br>
                            <br></br>
                            Nos services incluent des solutions d'ouvertures
                            claquées et de portes fermées simples pour un accès
                            rapide à votre domicile. Nous sommes également
                            spécialisés dans l'installation et la réparation de
                            serrures de haute qualité, le blindage pour
                            renforcer votre sécurité, ainsi que l'installation
                            et la réparation de volets roulants pour optimiser
                            la protection de vos fenêtres.
                        </p>
                    </article>
                </div>
                <div id="location">
                    <h4>Serrurie Dépanage</h4>
                    <span>Meaux 77100</span>
                </div>
            </div>
        </div>
    );
};

export default Accueil;
