import Header from '../Header/Header';
import Service from './Service/Service';
import { service } from './Service/InterfaceService';
import './Services.css';

const Services = () => {

    const serviceData : service[] = [
        { title: 'Ouverture d’une porte Claquée', logo: 'https://res.cloudinary.com/du0jhqyku/image/upload/v1699822932/lock_blrami.svg', price: '95 €', paragraphe: "Une porte fermée et la clé est restée à l’intérieur ? Pas de soucis nous disposons d’outils et techniques adaptés pour résoudre ce type de situation rapidement, sans endommager la serrure ou la porte." },
        { title: 'Ouverture d’une porte fermée à clé', logo: 'https://res.cloudinary.com/du0jhqyku/image/upload/v1699822931/key_kup2oh.svg', price: '145 €', paragraphe: "Vous avez perdu vos clés ? La clé s’est cassée dans la serrure ? Nous utilisons des méthodes adaptées afin d’être rapides et efficaces, qui visent également à endommager le moins possible la serrure et la porte." },
        { title: 'Installation de serrures', logo: 'https://res.cloudinary.com/du0jhqyku/image/upload/v1699822931/tool_wxiyvu.svg', price: '( Sur devis )', paragraphe: "Nous vous proposons de réparer ou de changer vos serrures quelles que soient leurs marques (Picard, Bricard, Vachette, Mottura…) ou leur fonctionnement (3 points, 7 points ou 9 points de sûreté)." },
        { title: 'Blindage de portes', logo: 'https://res.cloudinary.com/du0jhqyku/image/upload/v1699822931/tools_raimfx.svg', price: '( Sur devis )', paragraphe: "Nous vous offrons la possibilité d’augmenter la sécurité de votre domicile en renforçant votre porte grâce à nos différentes solutions de blindage de porte qui s’adapteront parfaitement à vos besoins." },
        { title: 'Installation de volets roulants', logo: 'https://res.cloudinary.com/du0jhqyku/image/upload/v1699822930/garage_u1mhsg.svg', price: '( Sur devis )', paragraphe: "Nous vous proposons l’installation de volets roulants de tout types (Manuel, motorisé, solaires…) en fonction de vos besoins. Nous réparons également vos volets roulants défaillants." },
    ];    

    return (
        <div id="servicePage">
            <Header name='Nos services'/>
            <div className="serviceContainer">
                { serviceData?.map((service: service, index: number)=>{
                    return <Service 
                                key={index}
                                logo={service.logo}
                                title={service.title}
                                paragraphe={service.paragraphe}
                                price={service.price}/>
                })}
            </div>
        </div>
    );
};

export default Services;