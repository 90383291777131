import './Service.css';
import { service } from './InterfaceService';
import AnimationGsap from '../../Animation/AnimationGsap/Animation';
import { useEffect } from 'react';

const Service = ( props : service ) => {

    const animationGsap = new AnimationGsap();

    useEffect(()=>{
        animationGsap.service();
    },[])

    return (
        <article>
            <div className="shadow"></div>
            <div className="service">
                <span>
                    <img src={props.logo} alt='logo service'/>
                </span>
                <h5>{props.title}</h5>
                <p>
                    {props.paragraphe}
                </p>
                <h4>{props.price}</h4>
            </div>
        </article>
    );
};

export default Service;