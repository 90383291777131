import Header from "../Header/Header";
import "./Article.css";
import Article from "./About/About";
import { article } from "./interfaceArticle";

const Articles = () => {
    const aboutMeData = [
        {
            logo: "https://res.cloudinary.com/du0jhqyku/image/upload/v1700306776/logo_rncp_gaw5ac_1_1_njgpb7.png",
            paragraphe:
                "Notre équipe est titulaire d’une certification RNCP niveau 4 de serrurier dépanneur et installateur, reconnu par l’État.",
        },
        {
            logo: "https://res.cloudinary.com/du0jhqyku/image/upload/v1699823042/euro_logo_mknv6e.png",
            paragraphe:
                "Le devis est totalement gratuit. Aucun montant ne vous sera facturé pour obtenir une estimation du prix des services.",
        },
        {
            logo: "https://res.cloudinary.com/du0jhqyku/image/upload/v1699823042/logo_heure_cmqngw.png",
            paragraphe:
                "Nous sommes à votre disponibilité 7j/7 et 24h/24. Nous répondrons à toutes vos sollicitations dès que vous en aurez besoin.",
        },
    ];

    return (
        <div id="aboutContainer">
            <Header name="Pourquoi nous ?" />
            <div id="aboutContent">
                {aboutMeData?.map((aboutData: article, index: number) => {
                    return (
                        <Article
                            key={index}
                            logo={aboutData.logo}
                            paragraphe={aboutData.paragraphe}
                        />
                    );
                })}
            </div>
        </div>
    );
};

export default Articles;
