import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

export default class AnimationGsap {

    constructor(){}

    public service()
    {
        gsap.registerPlugin(ScrollTrigger);

        return gsap.to('.service', { 
            scale: 1,
            duration: 0.9,
            stagger: 0.2,
            scrollTrigger:{
                start: "center-=35% top",
            } 
        });
    }

    public navbar()
    {
        gsap.to('.navbar li', { y: '0px', stagger: 0.09 });
    }

    public navbarResponsive() : gsap.core.Timeline
    {
        return ( 
            gsap.timeline({ paused: true })
                .to('.line', {
                    x: -100,
                    stagger: 0.17
                })
                .to('#responsive_panel', {
                    x: 0,
                    display: 'flex',
                    ease: "circ"
                })
                .to('#line1', {
                    y: 11,
                    x: 0,
                    rotate: 45
                }, 0.5)
                .to('#line2', {
                    x: 0,
                    rotate: -45
                }, 0.7)
                .to('.span_responsive', {
                    x: 0,
                    stagger: 0.1
                }, 1)
            )
    }

    public scrollUpNavbar = () => {
        gsap.to('.navbar',{ y: '-100%' })
    }
    public scrollDownNavbar = () => {
        gsap.to('.navbar',{ y: '0%' })
    }
}