import './App.css';
import Navbar from './Navbar/Navbar';
import Accueil from './Accueil/Accueil';
import Services from './Services/Services';
import Articles from './Articles/Article';
import Contact from './Contact/Contact';
import Footer from './Footer/Footer';
import Map from './Map/Map';

function App() {
  return (
    <div id="App">
        <Navbar/>
        <Accueil/>
        <Services/>
        <Articles/>
        <Contact/>
        <Map/>
        <Footer/>
    </div>
  );
}

export default App;
