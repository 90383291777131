import "./Contact.css";
import Header from "../Header/Header";
import { useState } from "react";
import InputData from "./InputData";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const Contact = () => {
    const [lastname, setLastname] = useState(String);
    const [firstname, setFirstname] = useState(String);
    const [email, setEmail] = useState(String);
    const [phonenumber, setPhonenumber] = useState(String);
    const [message, setMessage] = useState(String);
    const [services, setServices] = useState(String);

    const selectService = () => {
        const radioChecked: string ='#checkboxContainer input[type="checkbox"]:checked';
        const servicesSelected = document.querySelectorAll<HTMLInputElement>(radioChecked);

        const selectedValues = Array.from(servicesSelected)
            .map((inputChecked: HTMLInputElement) => inputChecked.value)
            .join(", ");

        setServices(selectedValues);
    };

    const displayErrorAlert = (message: string) => {
        toast.error(message, {
            position: "top-left",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
        });
    };

    async function sendEmail(e: React.MouseEvent<HTMLButtonElement>) {
        e.preventDefault();

        const data = {
            lastname: lastname,
            firstname: firstname,
            email: email,
            phonenumber: phonenumber,
            message: message,
            services: services,
        };

        const inputData = await new InputData(data);
        const validationResult = await inputData.isValid();

        if (!validationResult.response) {
            displayErrorAlert(validationResult.message);
        } else {
            const to = "Martin.serrures@protonmail.com";
            const subject = services;
            const body = `
                Bonjour Martin Serrure,

                J'ai envoyé une nouvelle demande de service de serrurerie via votre site web. Voici les détails que j'ai fournis :
                J'ai exprimé le besoin suivant : ${message}
                
                Nom : ${lastname}
                Prénom : ${firstname}
                Email : ${email}
                Numéro de téléphone : ${phonenumber}
                Service demandé : ${services}
                
                Merci de prendre contact avec moi dans les plus brefs délais pour discuter de mes besoins et planifier une intervention.
                
                Cordialement,
                ${lastname} ${firstname}
            `;

            const mailtoLink = `mailto:${to}?subject=${encodeURIComponent(
                subject
            )}&body=${encodeURIComponent(body)}`;

            window.open(mailtoLink, "_blank");

            const radioChecked: string =
                '#checkboxContainer input[type="checkbox"]:checked';
            const servicesSelected =
                document.querySelectorAll<HTMLInputElement>(radioChecked);

            servicesSelected.forEach((checkbox) => (checkbox.checked = false));
            setLastname("");
            setFirstname("");
            setEmail("");
            setPhonenumber("");
            setMessage("");
            setServices("");
        }
    }

    return (
        <div id="contactContainer">
            <ToastContainer
                position="top-left"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
                theme="light"
            />
            <Header name="Contact" />
            <h3 id="formGroup">
                Contactez-nous dès maintenant pour plus d'informations !
            </h3>
            <div id="formContainer">
                <form>
                    <div className="inputWrapper">
                        <label>Nom</label>
                        <input
                            className="lineInputBottom"
                            value={lastname}
                            onChange={(e) => setLastname(e.target.value)}
                            type="text"
                            placeholder="Jhon"
                            name="name"
                        />
                    </div>
                    <div className="inputWrapper">
                        <label>Prénom</label>
                        <input
                            className="lineInputBottom"
                            value={firstname}
                            onChange={(e) => setFirstname(e.target.value)}
                            type="text"
                            placeholder="Martin"
                            name="firstname"
                        />
                    </div>
                    <div className="inputWrapper">
                        <label>Email</label>
                        <input
                            className="lineInputBottom"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            type="email"
                            placeholder="exemple123@gmail.com"
                            name="email"
                        />
                    </div>
                    <div className="inputWrapper">
                        <label>Numéro de téléphone</label>
                        <input
                            className="lineInputBottom"
                            value={phonenumber}
                            onChange={(e) => setPhonenumber(e.target.value)}
                            type="number"
                            placeholder="09 08 79 89 89"
                            name="phone"
                        />
                    </div>
                    <h4 id="question">Votre question concerne ?</h4>
                    <div id="checkboxContainer">
                        <div className="checkbox">
                            <input
                                className="circle"
                                onClick={selectService}
                                value="Installation de serrures"
                                type="checkbox"
                                name="Installation serrure"
                                id="Installation serrure"
                            />
                            <label htmlFor="Installation serrure">
                                Installation de serrures
                            </label>
                        </div>
                        <div className="checkbox">
                            <input
                                className="circle"
                                onClick={selectService}
                                value="Blindage de portes"
                                type="checkbox"
                                name="Blindage"
                                id="Blindage"
                            />
                            <label htmlFor="Blindage">Blindage de portes</label>
                        </div>
                        <div className="checkbox">
                            <input
                                className="circle"
                                onClick={selectService}
                                value="Volets roulant"
                                type="checkbox"
                                name="Volets roulant"
                                id="Volets roulant"
                            />
                            <label htmlFor="Volets roulant">
                                Volets roulant
                            </label>
                        </div>
                        <div className="checkbox">
                            <input
                                className="circle"
                                onClick={selectService}
                                value="Autre"
                                type="checkbox"
                                name="Autre"
                                id="Autre"
                            />
                            <label htmlFor="Autre">Autre</label>
                        </div>
                    </div>
                    <h4 id="titleMessage">Message</h4>
                    <div className="text">
                        <input
                            className="bigLine"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            type="text"
                            placeholder="Je vous contacte pour une intervention visant une installation ..."
                            name=""
                        />
                    </div>
                    <div id="btnSend">
                        <button onClick={sendEmail} id="btn">
                            Envoyer
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
};

export default Contact;
