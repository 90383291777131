import "./About.css";
import { article } from "../interfaceArticle";

const Article = (props: article) => {
    return (
        <article className="about">
            <figure>
                <img draggable="false" src={props.logo} alt="logo rncp" />
            </figure>
            <div>
                <p>{props.paragraphe}</p>
            </div>
        </article>
    );
};

export default Article;
