import "./Navbar.css";
import { useEffect } from "react";
import AnimationGsap from "../Animation/AnimationGsap/Animation";

const Navbar = () => {
    const animationPage = new AnimationGsap();
    let animationNavbarResponsive: gsap.core.Timeline;
    let countClick = 0;

    useEffect(() => {
        animationNavbarResponsive = animationPage.navbarResponsive();

        return () => {
            animationNavbarResponsive.clear();
        };
    }, []);

    const clickBurger = () => {
        countClick += 1;

        if (countClick == 2) {
            animationNavbarResponsive.reverse();
            countClick = 0;
        } else {
            animationNavbarResponsive.play();
        }
    };

    const scrollTo = (path: string) => {
        const element = document.querySelector(path);
        if (element) {
            element.scrollIntoView({ behavior: "smooth" });
        }
    };

    return (
        <>
            <div id="homeBlock"></div>
            <header className="navbar">
                <figure id="logo">
                    <img
                        src="./logo.svg"
                        alt="logo navbar"
                        onClick={() => scrollTo("#homeBlock")}
                        draggable={false}
                    />
                    <div>
                        <span>06 66 47 16 17</span>
                        <span>Martin.serrures@protonmail.com</span>
                    </div>
                </figure>
                <nav>
                    <ul>
                        <li onClick={() => scrollTo("#homeBlock")}>accueil</li>
                        <li onClick={() => scrollTo("#servicePage")}>
                            services
                        </li>
                        <li onClick={() => scrollTo("#contactContainer")}>
                            contact
                        </li>
                    </ul>
                </nav>
                <figure onClick={clickBurger} id="burgerContent">
                    <img
                        src="https://res.cloudinary.com/du0jhqyku/image/upload/v1699823043/line_m2131d.svg"
                        alt="logo burgeur"
                        className="line"
                        id="line1"
                    />
                    <img
                        src="https://res.cloudinary.com/du0jhqyku/image/upload/v1699823043/line_m2131d.svg"
                        alt="logo burgeur"
                        className="line"
                        id="line2"
                    />
                    <img
                        src="https://res.cloudinary.com/du0jhqyku/image/upload/v1699823043/line_m2131d.svg"
                        alt="logo burgeur"
                        className="line"
                        id="line3"
                    />
                </figure>
            </header>
            <div id="responsive_panel">
                <nav>
                    <ul>
                        <li
                            onClick={() => (
                                scrollTo("#homeBlock"), clickBurger()
                            )}
                            className="span_responsive"
                        >
                            accueil
                        </li>
                        <li
                            onClick={() => (
                                scrollTo("#servicePage"), clickBurger()
                            )}
                            className="span_responsive"
                        >
                            services
                        </li>
                        <li
                            onClick={() => (
                                scrollTo("#contactContainer"), clickBurger()
                            )}
                            className="span_responsive"
                        >
                            contact
                        </li>
                    </ul>
                </nav>
            </div>
        </>
    );
};

export default Navbar;
