import React from "react";
import Header from "../Header/Header";
import "./Map.css";

const Map = () => {
    return (
        <div id="mapContainer">
            <div className="map">
                <iframe
                    title="Smappen - Carte partagée"
                    width="100%"
                    height="600"
                    src="https://www.smappen.fr/app/iframe/7py9g"
                    frameBorder="0"
                    allowFullScreen
                ></iframe>
            </div>
        </div>
    );
};

export default Map;
