interface InputDataInterface{
    lastname: string, 
    firstname: string, 
    email: string, 
    phonenumber: string,
    message: string, 
    services: string
}

interface response{
    response : boolean
    message : string
}

export default class InputData {

    private lastname : string;
    private firstname : string;
    private email : string;
    private phonenumber : string;
    private message : string;
    private services : string;

    constructor( InputData : InputDataInterface )
    {
        this.lastname = InputData.lastname;
        this.firstname = InputData.firstname;
        this.email = InputData.email;
        this.phonenumber = InputData.phonenumber;
        this.message = InputData.message;
        this.services = InputData.services;
    }

    private isValidEmail( email: string ) : response|void
    {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        if ( !emailRegex.test(email) ) {
            return { response: false, message: 'L\'adresse e-mail n\'est pas correcte' }
        }
    }

    private isEmptyServices ( services: string ) : response|void
    {
        if ( services?.length == 0 ) {
            return { response: false, message: 'Veuillez sélectionner un service' };
        }
    }

    private isEmptyFields ( inputsArray: string[] ) : response|void 
    {
        const hasEmptyField = inputsArray.some((inputValue: string) => inputValue?.length === 0);
        const hasShortField = inputsArray.some((inputValue: string) => inputValue?.length <= 2);
    
        if (hasEmptyField) {
            return { response: false, message: 'Remplissez tous les champs' };
        }
    
        if (hasShortField) {
            return { response: false, message: 'Veuillez remplir tous les champs' };
        }
    }

    public async isValid () : Promise<response>
    {
        const inputArray = [this.lastname, this.firstname, this.email, this.phonenumber, this.message];

        const servicesCheck = this.isEmptyServices(this.services);
        if (servicesCheck) {
            return servicesCheck;
        }
    
        const emailCheck = this.isValidEmail(this.email);
        if (emailCheck) {
            return emailCheck;
        }

        const fieldsCheck = this.isEmptyFields(inputArray);
        if (fieldsCheck) {
            return fieldsCheck;
        }

        return await { response: true, message: 'succes' };
    }
}